import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"

const ThankYou = () => (
  <Layout pageInfo={{ pageName: "CookiePolicy" }}>
    <Seo title="Thank you"/>

    <section className="general-page" id="thank-you">

      <div className="section-heading">
        <h2 className="title">Bedankt!</h2>
      </div>

      <Container>
        <Row>
          <Col>
            <div className="block">
              <p align="center">
                Bedankt dat je contact hebt opgenomen met Wondere Wereld van Tekeningen. We zullen zo spoedig mogelijk
                contact met je opnemen.
              </p>
              <p align="center">
                Reacties kunnen mogelijk in je spam belanden. We raden jullie dus aan deze te controleren.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default ThankYou
